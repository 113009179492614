@import url('https://fonts.googleapis.com/css2?family=Jost:wght@200;300;400&display=swap');
.design-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5vh 0;
  font-family: Jost;
  width: 60vw;
}

.design {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline {
  width: 80%;
  height: auto;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.timeline-content {
  padding: 20px;
  -webkit-box-shadow: 5px 5px 10px #13547a, -5px -5px 10px #242424;
          box-shadow: 5px 5px 10px #13547a, -5px -5px 10px #242424;
  border-radius: 5px;
  padding: 1rem;
  transition: 0.4s ease;
  overflow-wrap: break-word !important;
  margin: 1rem;
  margin-bottom: 20px;
  border-radius: 6px;
  width: auto;
}

.timeline-content-light {
    padding: 20px;
    -webkit-box-shadow: 5px 5px 10px #c9c9c9, -5px -5px 10px #242424;
            box-shadow: 5px 5px 10px #c9c9c9, -5px -5px 10px #242424;
    border-radius: 5px;
    padding: 1rem;
    transition: 0.4s ease;
    overflow-wrap: break-word !important;
    margin: 1rem;
    margin-bottom: 20px;
    border-radius: 6px;
  }

.timeline-component {
  margin: 0px 20px 20px 20px;
}

@media screen and (min-width: 768px) {
  .timeline {
    display: grid;
    grid-template-columns: 1fr 3px 1fr;
  }
  .timeline-middle {
    position: relative;
    background-image: linear-gradient(45deg, #F27121, #E94057, #8A2387);
    width: 3px;
    height: 100%;
  }
  .main-middle {
    opacity: 0;
  }
  .timeline-circle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-image: linear-gradient(45deg, #F27121, #E94057, #8A2387);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}